import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Alert from '../Alert/Alert';
import "./HoursManagement.css";

function HoursManagement() {
    const [users, setUsers] = useState([]);
    const [currentUserIndex, setCurrentUserIndex] = useState(0);
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
    const navigate = useNavigate();
    const location = useLocation();

    const fetchUsers = () => {
        fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/Hours.php')
            .then(response => response.json())
            .then(data => setUsers(data))
            .catch(error => console.error('Erreur lors de la récupération des utilisateurs:', error));
    };

    useEffect(() => {
        fetchUsers();

        const interval = setInterval(() => {
            fetchUsers();
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (location.state && location.state.updated) {
            const { userId, month, adjustedMinutes } = location.state;
            updateHours(userId, month, adjustedMinutes);
        }
    }, [location.state]);

    const getCurrentUser = () => {
        return users[currentUserIndex];
    };

    const nextUser = () => {
        setCurrentUserIndex((currentIndex) => (currentIndex + 1) % users.length);
    };

    const previousUser = () => {
        setCurrentUserIndex((currentIndex) => (currentIndex - 1 + users.length) % users.length);
    };

    const openDialog = (month) => {
        const currentUser = getCurrentUser();
        navigate('/hoursmanagement/dialog', { state: { user: currentUser, month } });
    };

    const updateHours = (userId, month, adjustedMinutes) => {
        setUsers(users => users.map(user => {
            if (user.id === userId) {
                const updatedHoursByMonth = user.hours_by_month.map(entry => {
                    if (entry.month === month) {
                        const totalMinutes = (parseInt(entry.total_hours.split('h')[0], 10) * 60) + (parseInt(entry.total_hours.split('h')[1], 10)) + adjustedMinutes;
                        const sign = totalMinutes < 0 ? '-' : '';
                        const hours = Math.floor(Math.abs(totalMinutes) / 60);
                        const minutes = Math.abs(totalMinutes) % 60;
                        return { ...entry, total_hours: `${sign}${hours}h ${minutes < 10 ? '0' : ''}${minutes}m` };
                    }
                    return entry;
                });
                return { ...user, hours_by_month: updatedHoursByMonth };
            }
            return user;
        }));
    };

    const currentUser = getCurrentUser();

    const sortedHoursByMonth = currentUser?.hours_by_month?.sort((a, b) => {
        const dateA = new Date(a.month + '-01');
        const dateB = new Date(b.month + '-01');
        return dateB - dateA;
    });

    return (
        <div className='background-container-hours'>
            <div className='container-hours'>
                <h1>Gestion des heures</h1>
                <div className='container-users-choice'>
                    <div className='choice-users'>
                        <FontAwesomeIcon
                            className='icon'
                            icon={faChevronLeft}
                            onClick={previousUser}
                        />
                        {currentUser && currentUser.username}
                        <FontAwesomeIcon
                            className='icon'
                            icon={faChevronRight}
                            onClick={nextUser}
                        />
                    </div>
                </div>
                {alertMessage.message && (
                    <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({ message: '', type: '' })} />
                )}
                <table>
                    <tbody>
                        {sortedHoursByMonth && sortedHoursByMonth.map((entry, index) => (
                            <tr key={index}>
                                <td>{currentUser.username}</td>
                                <td>{new Date(entry.month + '-01').toLocaleString('fr-FR', { month: 'long', year: 'numeric' })}</td>
                                <td>{entry.total_hours}</td>
                                <td>
                                    <FontAwesomeIcon
                                        className='icon-modify'
                                        icon={faPen}
                                        onClick={() => openDialog(entry.month)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default HoursManagement;
