import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import Alert from '../Alert/Alert';
import { useAuth } from '../Services/AuthContext';
import "./UserManagement.css";

function UserManagement() {
    const { user: currentUser } = useAuth();
    const [users, setUsers] = useState([]);
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const location = useLocation();

    const addUser = useCallback((user) => {
        setUsers(prevUsers => [...prevUsers, user]);
    }, []);

    useEffect(() => {
        fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/UserManagement.php')
            .then(response => response.json())
            .then(data => {
                setUsers(data);
            })
            .catch(error => console.error('Erreur lors de la récupération des utilisateurs:', error));
    }, []);

    useEffect(() => {
        if (location.state && location.state.updated) {
            const { user } = location.state;
            addUser(user);
        }
    }, [location.state, addUser]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getPermissionLabel = (permission) => {
        if (windowWidth < 600) {
            switch (permission) {
                case 'admin':
                    return 'Admi';
                case 'moderator':
                    return 'Modé';
                default:
                    return permission;
            }
        } else {
            switch (permission) {
                case 'admin':
                    return 'Administrateur';
                case 'moderator':
                    return 'Modérateur';
                default:
                    return permission;
            }
        }
    };

    const permissions = ['moderator', 'admin'];

    const changePermission = (id, direction) => {
        if (id === currentUser.id) {
            setAlertMessage({ message: "Vous ne pouvez pas modifier vos propres permissions.", type: 'error' });
            return;
        }

        const user = users.find(user => user.id === id);
        if (!user) return;

        const currentIndex = permissions.indexOf(user.permission);
        const newIndex = currentIndex + direction;

        if (newIndex >= 0 && newIndex < permissions.length) {
            const newPermission = permissions[newIndex];

            fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/UserManagement.php', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id, permission: newPermission }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.message === "Permission mise à jour avec succès") {
                        setUsers(users.map(user =>
                            user.id === id ? { ...user, permission: newPermission } : user
                        ));
                        setAlertMessage({ message: 'Permission mise à jour avec succès', type: 'success' });
                    } else {
                        setAlertMessage({ message: `Erreur lors de la mise à jour de la permission: ${data.message}`, type: 'error' });
                    }
                })
                .catch(error => {
                    setAlertMessage({ message: `Erreur lors de la mise à jour de la permission: ${error.message}`, type: 'error' });
                });
        } else {
            console.error('Changement de permission invalide demandé.');
        }
    };

    const deleteUser = (id) => {
        if (id === currentUser.id) {
            setAlertMessage({ message: "Vous ne pouvez pas vous supprimer vous-même.", type: 'error' });
            return;
        }

        fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/UserManagement.php', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.message === "Utilisateur supprimé avec succès") {
                    setUsers(users.filter(user => user.id !== id));
                    setAlertMessage({ message: 'Utilisateur supprimé avec succès', type: 'success' });
                } else {
                    console.error('Erreur lors de la suppression de l\'utilisateur:', data.message);
                    setAlertMessage({ message: `Erreur lors de la suppression de l'utilisateur: ${data.message}`, type: 'error' });
                }
            })
            .catch(error => {
                console.error('Erreur lors de la suppression de l\'utilisateur:', error);
                setAlertMessage({ message: `Erreur lors de la suppression de l'utilisateur: ${error.message}`, type: 'error' });
            });
    };

    const openDialog = () => {
        navigate('/usermanagement/dialog', { state: { user: null } });
    };

    return (
        <div className='background-container-users'>
            <div className='container-users'>
                <h1>Gestion des utilisateurs</h1>
                <div className='container-add-btn'>
                    <button onClick={openDialog}>Créer un utilisateur</button>
                </div>
                {alertMessage.message && (
                    <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({ message: '', type: '' })} />
                )}
                <table>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.id}>
                                <td>{user.username}</td>
                                <td>
                                    <div className='container-permission'>
                                        <FontAwesomeIcon
                                            className='icon'
                                            icon={faChevronLeft}
                                            onClick={() => changePermission(user.id, -1)}
                                        />
                                        {getPermissionLabel(user.permission)}
                                        <FontAwesomeIcon
                                            className='icon'
                                            icon={faChevronRight}
                                            onClick={() => changePermission(user.id, 1)}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        className='icon-delete'
                                        icon={faXmark}
                                        onClick={() => deleteUser(user.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default UserManagement;
