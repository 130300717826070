import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from '../Alert/Alert';
import "./TasksManagementDialog.css";

function TasksManagementDialog() {
    const location = useLocation();
    const navigate = useNavigate();
    const { task } = location.state || {};
    
    const [closing, setClosing] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
    const [formData, setFormData] = useState({
        description: task?.description || '',
        visibility: task?.visibility || 'group',
        priority: task?.priority || 1,
        client: task?.client || '',
        folder_id: task?.folder_id || '',
        onedrive: task?.onedrive || '' // Ajout du champ OneDrive
    });
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const dialog = document.querySelector('.background-dialog-tasks-management');
        setTimeout(() => {
            dialog.classList.add('show');
        }, 10);

        fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/TasksManagement.php?type=users')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`La réponse du réseau n'était pas correcte: ${response.statusText}`);
                }
                return response.json();
            })
            .then(data => {
                setUsers(data);
            })
            .catch(error => console.error('Erreur lors de la récupération des utilisateurs:', error));
    }, []);

    useEffect(() => {
        if (task) {
            setFormData({
                description: task.description || '',
                visibility: task.visibility || 'group',
                priority: task.priority || 1,
                client: task.client || '',
                folder_id: task.folder_id || '',
                onedrive: task.onedrive || ''
            });
        }
    }, [task]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {
        const taskData = {
            client: formData.client,
            folder_id: formData.folder_id,
            description: formData.description,
            visibility: formData.visibility,
            priority: formData.priority,
            status: task ? task.status : 'in-progress',
            onedrive: formData.onedrive
        };
    
        if (task) {
            taskData.id = task.id;
        }

        const url = 'https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/TasksManagement.php';
        const method = task ? 'PUT' : 'POST';
    
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(taskData),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`La réponse du réseau n'était pas correcte: ${response.statusText}`);
            }
            return response.json();
        })
        .then(data => {
            if (data.success) {
                navigate('/tasksmanagement', { state: { updated: true } });
            } else {
                setAlertMessage({ message: 'Échec de la mise à jour de la tâche', type: 'error' });
            }
        })
        .catch(error => {
            setAlertMessage({ message: 'Échec de la mise à jour de la tâche', type: 'error' });
            console.error('Erreur:', error);
        });
    };

    const handleCloseDialog = () => {
        setClosing(true);
        setTimeout(() => {
            navigate('/tasksmanagement');
        }, 300);
    };

    const getVisibilityOptions = () => {
        const currentVisibility = formData.visibility;
        let options = [];

        if (currentVisibility === 'group') {
            options = users.map(user => (
                <option key={user.id} value={user.id}>{user.username}</option>
            ));
            options.unshift(<option key="group" value="group">Au groupe</option>);
        } else {
            const otherUsers = users.filter(user => user.id !== parseInt(currentVisibility));
            options = otherUsers.map(user => (
                <option key={user.id} value={user.id}>{user.username}</option>
            ));
            options.unshift(<option key="group" value="group">Au groupe</option>);
            const currentUser = users.find(user => user.id === parseInt(currentVisibility));
            if (currentUser) {
                options.unshift(<option key={currentUser.id} value={currentUser.id}>{currentUser.username}</option>);
            }
        }
        return options;
    };

    return (
        <div className={`background-dialog-tasks-management ${closing ? 'closing' : ''}`}>
            {alertMessage.message && (
                <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({ message: '', type: '' })} />
            )}
            <div className='dialog-tasks-management-view'>
                <h1>{task ? 'Détails de la tâche' : 'Création d\'une tâche'}</h1>
                <div className='container'>
                    <div className='input-box'>
                        <label htmlFor="client">Entreprise/Client</label>
                        <input type="text" name="client" id="client" value={formData.client} onChange={handleChange} />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="folder">Numéro du dossier</label>
                        <input type="text" name="folder_id" id="folder_id" value={formData.folder_id} onChange={handleChange} />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="description">Description de la tâche</label>
                        <textarea name="description" id="description" value={formData.description} onChange={handleChange}></textarea>
                    </div>
                    <div className='input-box'>
                        <label htmlFor="onedrive">Lien OneDrive</label> {/* Ajout du champ OneDrive */}
                        <input type="text" name="onedrive" id="onedrive" value={formData.onedrive} onChange={handleChange} />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="visibility">Attribuer à</label>
                        <select name="visibility" id="visibility" value={formData.visibility} onChange={handleChange}>
                            {getVisibilityOptions()}
                        </select>
                    </div>
                    <div className='input-box'>
                        <label htmlFor="priority">La priorité de la tâche</label>
                        <select name="priority" id="priority" value={formData.priority} onChange={handleChange}>
                            <option value="1">Niveau 1</option>
                            <option value="2">Niveau 2</option>
                            <option value="3">Niveau 3</option>
                            <option value="4">Niveau 4</option>
                            <option value="5">Niveau 5</option>
                        </select>
                    </div>
                    <div className='container-btn'>
                        <button onClick={handleCloseDialog}>Annuler</button>
                        <button onClick={handleSubmit}>
                            {task ? 'Appliquer' : 'Créer'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TasksManagementDialog;
