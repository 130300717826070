import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Connection from './TodoLogix/Connection/Connection';
import Header from './TodoLogix/Header/Header';
import Footer from './TodoLogix/Footer/Footer';
import Tasks from './TodoLogix/Tasks/Tasks';
import Hours from './TodoLogix/Hours/Hours'; 
import UserManagement from './TodoLogix/UserManagement/UserManagement'; 
import HoursManagement from './TodoLogix/HoursManagement/HoursManagement'; 
import TasksManagement from './TodoLogix/TasksManagement/TasksManagement'; 
import TasksManagementDialog from './TodoLogix/TasksManagementDialog/TasksManagementDialog';
import HoursManagementDialog from './TodoLogix/HoursManagementDialog/HoursManagementDialog';
import UserManagementDialog from './TodoLogix/UserManagementDialog/UserManagementDialog';
import TasksDialog from './TodoLogix/TasksDialog/TasksDialog';
import { AuthProvider, useAuth } from './TodoLogix/Services/AuthContext';
import { TasksProvider } from './TodoLogix/Services/TasksContext';
import ProtectedRoute from './TodoLogix/Services/ProtectedRoute';
import NoMatch from './TodoLogix/Services/NoMatch';

const App = () => {
  const { user } = useAuth();
  const location = useLocation();

  return (
    <div className="App">
      <Header />
      <main>
        <TasksProvider>
          <Routes>
            <Route path="/" element={user ? <Navigate to={user.permission === 'admin' ? "/tasksmanagement" : "/tasks"} /> : <Connection />} />
            <Route path="/tasks" element={<ProtectedRoute component={Tasks} requiredPermission="moderator" />} />
            <Route path="/usermanagement" element={<ProtectedRoute component={UserManagement} requiredPermission="admin" />} />
            <Route path="/hoursmanagement" element={<ProtectedRoute component={HoursManagement} requiredPermission="admin" />} />
            <Route path="/tasksmanagement" element={<ProtectedRoute component={TasksManagement} requiredPermission="admin" />} />
            <Route path="/tasksmanagement/dialog" element={<ProtectedRoute component={TasksManagementDialog} requiredPermission="admin" />} />
            <Route path="/hoursmanagement/dialog" element={<ProtectedRoute component={HoursManagementDialog} requiredPermission="admin" />} />
            <Route path="/usermanagement/dialog" element={<ProtectedRoute component={UserManagementDialog} requiredPermission="admin" />} />
            <Route path="/tasks/dialog" element={<ProtectedRoute component={TasksDialog} requiredPermission="moderator" />} />
            <Route path="/hours" element={<ProtectedRoute component={Hours} requiredPermission="moderator" />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </TasksProvider>
      </main>
      {location.pathname === '/' && <Footer />}
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Router>
);

export default AppWrapper;
