import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup, faUser } from '@fortawesome/free-solid-svg-icons';
import Alert from '../Alert/Alert';
import "./TasksManagement.css";

function TasksManagement() {
    const [tasks, setTasks] = useState([]);
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
    const navigate = useNavigate();

    const fetchTasks = () => {
        fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/TasksManagement.php')
            .then(response => response.json())
            .then(data => setTasks(Array.isArray(data) ? data : [])) 
            .catch(error => console.error('Erreur lors de la récupération des tâches:', error));
    };

    useEffect(() => {
        fetchTasks();
        const interval = setInterval(() => {
            fetchTasks();
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    const openDialog = (task) => {
        navigate('/tasksmanagement/dialog', { state: { task } });
    };

    const toggleTaskStatus = (task) => {
        const newStatus = task.status === 'finish' ? 'in-progress' : 'finish';
        const updatedTask = { ...task, status: newStatus };

        fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/TasksManagement.php', {
            method: 'PUT', 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedTask), 
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setTasks(tasks.map(t => (t.id === updatedTask.id ? updatedTask : t)));
            } else {
                setAlertMessage({ message: data.message, type: 'error' });
            }
        })
        .catch(error => {
            setAlertMessage({ message: 'Erreur lors de la mise à jour du statut de la tâche', type: 'error' });
            console.error('Erreur lors de la mise à jour du statut de la tâche:', error);
        });
    };

    return (
        <div className='background-container-tasks-management'>
            <div className='container-tasks-management'>
                <h1>Gestion des tâches</h1>
                {alertMessage.message && (
                    <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({ message: '', type: '' })} />
                )}
                <div className='container-add-btn'>
                    <button onClick={() => openDialog(null)}>Création d'une tâche</button>
                </div>
                <div className='container-box'>
                    {tasks.map((task, index) => (
                        <div className='box' key={task.id || `task-${index}`}>
                            <h2>{task.client}</h2>
                            <h3>N° {task.folder_id}</h3>
                            <div className='container-icon'>
                                <FontAwesomeIcon className='icon' icon={task.visibility === 'group' ? faUserGroup : faUser} />
                            </div>
                            <div className='container-btn'>
                                <span
                                    className={task.status === 'finish' ? 'closed' : 'in-progress'}
                                    onClick={() => toggleTaskStatus(task)}
                                >
                                    {task.status === 'finish' ? 'Clôturée' : 'En cours'}
                                </span>
                                <button onClick={() => openDialog(task)}>Afficher</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TasksManagement;
