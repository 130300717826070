import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Alert from '../Alert/Alert';
import "./HoursManagementDialog.css";

function HoursManagementDialog() {
    const location = useLocation();
    const navigate = useNavigate();
    const { user, month } = location.state || {};

    const [closing, setClosing] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
    const [time, setTime] = useState('');
    const [isAdding, setIsAdding] = useState(true);

    useEffect(() => {
        const dialog = document.querySelector('.background-dialog-hours');
        setTimeout(() => {
            dialog.classList.add('show');
        }, 10);
    }, []);

    const handleCloseDialog = () => {
        setClosing(true);
        setTimeout(() => {
            navigate('/hoursmanagement');
        }, 300);
    };

    const handleAddTime = () => {
        const timeParts = time.split(':');
        const hours = parseInt(timeParts[0] || '0', 10);
        const minutes = parseInt(timeParts[1] || '0', 10);
        const totalMinutes = hours * 60 + minutes;
        const adjustedMinutes = isAdding ? totalMinutes : -totalMinutes;

        fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/Hours.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: user.id,
                month,
                minutes: adjustedMinutes,
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.message === "Hours updated successfully") {
                navigate('/hoursmanagement', { state: { updated: true, userId: user.id, month, adjustedMinutes } });
            }
            setAlertMessage({ message: data.message, type: 'success' });
            handleCloseDialog();
        })
        .catch(error => {
            setAlertMessage({ message: 'Failed to update hours', type: 'error' });
            console.error('Error:', error);
        });
    };

    const toggleAddRemove = () => {
        setIsAdding(!isAdding);
    };

    const handleTimeChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 4) {
            value = value.slice(0, 4);
        }

        if (value.length >= 3) {
            value = value.slice(0, 2) + ':' + value.slice(2);
        } else if (value.length >= 1) {
            value = value.slice(0, 2);
        }

        if (value.includes(':')) {
            const parts = value.split(':');
            if (parts[1] && parseInt(parts[1]) > 59) {
                parts[1] = '59';
            }
            value = parts.join(':');
        }

        setTime(value);
    };

    return (
        <div className={`background-dialog-hours ${closing ? 'closing' : ''}`}>
            {alertMessage.message && (
                <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({ message: '', type: '' })} />
            )}
            <div className='dialog-hours-modify'>
                <h1>Modifier les heures</h1>
                <div className='input-box'>
                    <label htmlFor="number">Ajouter/retirer des heures</label>
                    <div className='container-input-time'>
                        <input 
                            type="text" 
                            name="number" 
                            id="time" 
                            value={time}
                            onChange={handleTimeChange}
                        />
                        <span onClick={toggleAddRemove}>
                            <FontAwesomeIcon icon={isAdding ? faPlus : faMinus} />
                        </span>
                    </div>
                </div>
                <div className='container-btn'>
                    <button onClick={handleCloseDialog} >Annuler</button>
                    <button onClick={handleAddTime} >Confirmer</button>
                </div>
            </div>
        </div>
    );
}

export default HoursManagementDialog;
