import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Services/AuthContext';
import Alert from '../Alert/Alert';
import './Connection.css';

function Connection() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleLogin = async (e) => {
        e.preventDefault();
        setAlertMessage({ message: '', type: '' });

        try {
            const response = await fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/Connection.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password })
            });

            if (!response.ok) {
                throw new Error('Erreur de réseau');
            }

            const data = await response.json();

            if (data.status === 'success') {
                login({ id: data.id, username: data.username, permission: data.permission });
                if (data.permission === 'admin') {
                    navigate('/TasksManagement'); 
                } else if (data.permission === 'moderator') {
                    navigate('/tasks');
                }
            } else {
                setAlertMessage({ message: data.message, type: 'error' });
            }
        } catch (error) {
            console.error('Erreur lors de la connexion:', error);
            setAlertMessage({ message: 'Une erreur est survenue. Veuillez réessayer.', type: 'error' });
        }
    };

    const handleCloseAlert = () => {
        setAlertMessage({ message: '', type: '' });
    };

    return (
        <div className="container-connection">
            <h2>Connexion</h2>
            <form onSubmit={handleLogin}>
                <div className='input-box'>
                    <label htmlFor="username">Utilisateur</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        autoComplete="username"
                    />
                </div>
                <div className='input-box'>
                    <label htmlFor="password">Mot de passe</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        autoComplete="current-password"
                    />
                </div>
                <div className='container-btn'>
                    <button type="submit">Se connecter</button>
                </div>
            </form>
            {alertMessage.message &&
                <Alert
                    message={alertMessage.message}
                    type={alertMessage.type}
                    onClose={handleCloseAlert}
                />
            }
        </div>
    );
}

export default Connection;
