// Tasks.jsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Tasks.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup, faUser, faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import Alert from '../Alert/Alert';
import { useAuth } from '../Services/AuthContext';
import { useTasks } from '../Services/TasksContext';

function Tasks() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { tasks, activeTasks, alertMessage, setAlertMessage, fetchTasks } = useTasks();

    useEffect(() => {
        fetchTasks();
    }, [fetchTasks]);

    const handleOpenDialog = () => {
        navigate('/tasks/dialog', { state: { task: null } });
    };

    const handleViewTask = (task) => {
        navigate('/tasks/dialog', { state: { task } });
    };

    const getStatusClass = (status) => {
        return status === 'finish' ? 'closed' : 'in-progress';
    };

    const getLocalIsoString = (date) => {
        const offset = date.getTimezoneOffset();
        const newDate = new Date(date.getTime() - (offset * 60 * 1000));
        return newDate.toISOString().split('Z')[0];
    };

    const handleStartTask = async (task) => {
        if (!user || !user.id) {
            setAlertMessage({ message: 'Utilisateur non authentifié', type: 'error' });
            return;
        }

        const dataToSubmit = {
            task_id: task.id,
            user_id: user.id,
            start_time: getLocalIsoString(new Date())
        };

        const response = await fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/TaskTimeEntries.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSubmit)
        });

        if (response.ok) {
            setAlertMessage({ message: 'Tâche commencée avec succès', type: 'success' });
            fetchTasks();
        } else {
            const errorMessage = await response.json();
            setAlertMessage({ message: `Échec du démarrage de la tâche : ${errorMessage.message}`, type: 'error' });
        }
    };

    const handleStopTask = async (task) => {
        if (!user || !user.id) {
            setAlertMessage({ message: 'Utilisateur non authentifié', type: 'error' });
            return;
        }

        const dataToSubmit = {
            task_id: task.id,
            user_id: user.id,
            end_time: getLocalIsoString(new Date())
        };

        const response = await fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/TaskTimeEntries.php', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSubmit)
        });

        if (response.ok) {
            setAlertMessage({ message: 'Tâche arrêtée avec succès', type: 'success' });
            fetchTasks();
        } else {
            const errorMessage = await response.json();
            setAlertMessage({ message: `Échec de l'arrêt de la tâche : ${errorMessage.message}`, type: 'error' });
        }
    };

    const filteredTasks = tasks.filter(task => task.visibility === 'group' || task.visibility === user.id.toString());

    return (
        <div className='background-container-tasks'>
            <div className='container-tasks'>
                <h1>Mes tâches</h1>
                <div className='container-add-btn'>
                    <button onClick={handleOpenDialog}>Création d'une tâche</button>
                </div>
                {alertMessage.message && (
                    <Alert 
                        message={alertMessage.message} 
                        type={alertMessage.type} 
                        onClose={() => setAlertMessage({ message: '', type: '' })} 
                    />
                )}
                <div className='container-box'>
                    {filteredTasks.map(task => (
                        <div className='box' key={task.id}>
                            <h2>{task.client}</h2>
                            <h3>N°{task.folder_id}</h3>
                            <div className='container-icon'>
                                <div className='visibility'>
                                    <FontAwesomeIcon className='icon' icon={task.visibility === 'group' ? faUserGroup : faUser} />
                                </div>
                                <div className='status'>
                                    <FontAwesomeIcon 
                                        className='icon' 
                                        icon={activeTasks[task.id] ? faPause : faPlay} 
                                        onClick={() => activeTasks[task.id] ? handleStopTask(task) : handleStartTask(task)}
                                        style={{ color: activeTasks[task.id] ? '#cf5e5e' : '#6fbe7a' }} 
                                    />
                                </div>
                            </div>
                            <div className='container-btn'>
                                {task.onedrive && (
                                    <a href={task.onedrive} className='onedrive' target="_blank" rel="noopener noreferrer">OneDrive</a>
                                )}
                                <span className={getStatusClass(task.status)}>
                                    {task.status === 'finish' ? 'Clôturée' : 'En cours'}
                                </span>
                                <button onClick={() => handleViewTask(task)}>Afficher</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Tasks;
