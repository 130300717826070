import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Hours.css';
import { useAuth } from '../Services/AuthContext';

function Hours() {
    const [closing, setClosing] = useState(false);
    const [totalTime, setTotalTime] = useState('');
    const [loading, setLoading] = useState(true);
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const dialog = document.querySelector('.background-dialog-user-hours');
        setTimeout(() => {
            dialog.classList.add('show');
        }, 10);

        const fetchTotalHours = async () => {
            try {
                const response = await fetch(`https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/TaskTimeEntries.php?user_id=${user.id}`);
                const data = await response.json();
                const totalMinutes = data.total_minutes || 0;
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;

                if (hours === 0) {
                    setTotalTime(`${minutes} min`);
                } else {
                    setTotalTime(`${hours}h${minutes.toString().padStart(2, '0')}`);
                }
            } catch (error) {
                console.error('Erreur de récupération des heures de travail:', error);
                setTotalTime('Erreur');
            } finally {
                setLoading(false);
            }
        };

        fetchTotalHours();

        const intervalId = setInterval(fetchTotalHours, 60000);

        return () => clearInterval(intervalId); 
    }, [user.id]);

    const handleClose = () => {
        setClosing(true);
        setTimeout(() => {
            navigate(-1);
        }, 300);
    };

    return (
        <div className={`background-dialog-user-hours ${closing ? 'closing' : ''}`}>
            <div className='container-hours'>
                {loading ? (
                    <p>Chargement...</p>
                ) : (
                    <p>Ce mois-ci, <br /> vous avez travaillé <span>{totalTime}</span>.</p>
                )}
                <div className='container-btn'>
                    <button onClick={handleClose}>Fermer</button>
                </div>
            </div>
        </div>
    );
}

export default Hours;
