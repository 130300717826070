// TasksContext.jsx
import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';

const TasksContext = createContext();

export const TasksProvider = ({ children }) => {
    const { user } = useAuth();
    const [tasks, setTasks] = useState([]);
    const [activeTasks, setActiveTasks] = useState({});
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });

    const fetchTasks = useCallback(() => {
        fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/Tasks.php')
            .then(response => response.json())
            .then(data => {
                setTasks(data);
                data.forEach(task => {
                    const url = `https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/TaskTimeEntries.php?task_id=${task.id}&user_id=${user.id}`;
                    fetch(url)
                        .then(response => response.json())
                        .then(data => {
                            if (data && data.status === 'active') {
                                setActiveTasks(prevActiveTasks => ({ ...prevActiveTasks, [task.id]: true }));
                            } else {
                                setActiveTasks(prevActiveTasks => ({ ...prevActiveTasks, [task.id]: false }));
                            }
                        })
                        .catch(error => console.error(`Error fetching task status from ${url}:`, error));
                });
            })
            .catch(error => console.error('Error fetching tasks:', error));
    }, [user]);

    useEffect(() => {
        if (user) {
            fetchTasks();
        }
    }, [user, fetchTasks]);

    return (
        <TasksContext.Provider value={{ tasks, setTasks, activeTasks, setActiveTasks, alertMessage, setAlertMessage, fetchTasks }}>
            {children}
        </TasksContext.Provider>
    );
};

export const useTasks = () => useContext(TasksContext);
