import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import Logo from '../Image/favicon.png';
import { useAuth } from '../Services/AuthContext';
import './Header.css';

function Header({ onLinkClick }) {
    const [isNavVisible, setIsNavVisible] = useState(false);
    const { user, logout } = useAuth();

    useEffect(() => {
        if (isNavVisible) {
            document.body.classList.add('no-scroll-header');
        } else {
            document.body.classList.remove('no-scroll-header');
        }
    }, [isNavVisible]);

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
    };

    return (
        <header>
            <ul>
                <div className='container-logo'>
                    <img src={Logo} alt="Logo" draggable="false" />
                </div>

                {user && (
                    <>
                        <FontAwesomeIcon icon={faBars} className="menu-icon" onClick={toggleNav} />
                        <div className={`container-nav ${isNavVisible ? 'visible' : ''}`}>
                            <div className='container-close-icon' onClick={toggleNav}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                            {user.permission === 'moderator' && (
                                <>
                                    <li><Link className='link' to="/hours" onClick={() => { onLinkClick && onLinkClick(); toggleNav(); }}>Mes heures</Link></li>
                                    <li><Link className='link' to="/tasks" onClick={() => { onLinkClick && onLinkClick(); toggleNav(); }}>Mes tâches</Link></li>
                                </>
                            )}
                            {user.permission === 'admin' && (
                                <>
                                    <li><Link className='link' to="/UserManagement" onClick={() => { onLinkClick && onLinkClick(); toggleNav(); }}>Gestion des utilisateurs</Link></li>
                                    <li><Link className='link' to="/TasksManagement" onClick={() => { onLinkClick && onLinkClick(); toggleNav(); }}>Gestion des tâches</Link></li>
                                    <li><Link className='link' to="/HoursManagement" onClick={() => { onLinkClick && onLinkClick(); toggleNav(); }}>Gestion des heures</Link></li>
                                </>
                            )}
                            <li><Link className='link' to="/" onClick={() => { logout(); toggleNav(); }}>Déconnexion</Link></li>
                        </div>
                    </>
                )}
            </ul>
        </header>
    );
}

export default Header;
