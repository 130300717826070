// TasksDialog.jsx
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./TasksDialog.css";
import Alert from '../Alert/Alert';
import { useAuth } from '../Services/AuthContext';
import { useTasks } from '../Services/TasksContext';

function TasksDialog() {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useAuth();
    const { fetchTasks } = useTasks();
    const { task } = location.state || {};

    const [closing, setClosing] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
    const [formData, setFormData] = useState({
        client: '',
        folder: '',
        description: '',
        visibility: 'individual',
        priority: 'level_1',
        onedrive: '' 
    });
    const [isTaskStarted, setIsTaskStarted] = useState(false);

    useEffect(() => {
        const dialog = document.querySelector('.background-dialog-tasks');
        setTimeout(() => {
            dialog.classList.add('show');
        }, 10);

        if (task) {
            setFormData({
                client: task.client,
                folder: task.folder_id,
                description: task.description,
                visibility: task.visibility,
                priority: `level_${task.priority}`,
                onedrive: task.onedrive || ''
            });

            fetch(`https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/TaskTimeEntries.php?task_id=${task.id}&user_id=${user.id}`)
                .then(response => response.json())
                .then(data => {
                    if (data && data.status === 'active') {
                        setIsTaskStarted(true);
                    }
                })
                .catch(error => console.error('Échec de la récupération du statut de la tâche :', error));
        }
    }, [task, user.id]);

    const handleClose = () => {
        setClosing(true);
        setTimeout(() => {
            navigate('/tasks');
        }, 300);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleKeyDown = (e) => {
        const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'];
        if (!allowedKeys.includes(e.key) && !/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    const validateForm = () => {
        if (!formData.client || !formData.folder || !formData.description) {
            setAlertMessage({ message: 'Veuillez remplir tous les champs obligatoires.', type: 'error' });
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        const priorityMapping = {
            'level_1': 1,
            'level_2': 2,
            'level_3': 3,
            'level_4': 4,
            'level_5': 5
        };

        let visibility = formData.visibility;
        if (formData.visibility === 'individual') {
            visibility = user.id; 
        }

        const dataToSubmit = {
            client: formData.client,
            folder_id: formData.folder,
            description: formData.description,
            visibility: visibility,
            priority: priorityMapping[formData.priority],
            time: 0
        };

        if (formData.onedrive) {
            dataToSubmit.onedrive = formData.onedrive;
        }

        const response = await fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/Tasks.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSubmit)
        });

        if (response.ok) {
            fetchTasks(); 
            handleClose();
        } else {
            const errorMessage = await response.json();
            setAlertMessage({ message: `${errorMessage.message}`, type: 'error' });
        }
    };

    const getLocalIsoString = (date) => {
        const offset = date.getTimezoneOffset();
        const newDate = new Date(date.getTime() - (offset * 60 * 1000));
        return newDate.toISOString().split('Z')[0];
    };

    const handleStartTask = async () => {
        if (!user || !user.id) {
            setAlertMessage({ message: 'Utilisateur non authentifié', type: 'error' });
            return;
        }

        const dataToSubmit = {
            task_id: task.id,
            user_id: user.id,
            start_time: getLocalIsoString(new Date()) 
        };

        const response = await fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/TaskTimeEntries.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSubmit)
        });

        if (response.ok) {
            setAlertMessage({ message: 'Tâche commencée avec succès', type: 'success' });
            setIsTaskStarted(true);
        } else {
            const errorMessage = await response.json();
            setAlertMessage({ message: `Échec du démarrage de la tâche : ${errorMessage.message}`, type: 'error' });
        }
    };

    const handleStopTask = async () => {
        if (!user || !user.id) {
            setAlertMessage({ message: 'Utilisateur non authentifié', type: 'error' });
            return;
        }
    
        const dataToSubmit = {
            task_id: task.id,
            user_id: user.id,
            end_time: getLocalIsoString(new Date()) 
        };
    
        try {
            const response = await fetch('https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/TaskTimeEntries.php', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSubmit)
            });
    
            if (response.ok) {
                setAlertMessage({ message: 'Tâche arrêtée avec succès', type: 'success' });
                setIsTaskStarted(false);
            } else {
                const errorMessage = await response.json();
                setAlertMessage({ message: `Échec de l'arrêt de la tâche : ${errorMessage.message}`, type: 'error' });
            }
        } catch (error) {
            console.error('Erreur de fetch :', error);
        }
    };

    return (
        <div className={`background-dialog-tasks ${closing ? 'closing' : ''}`}>
            {alertMessage.message && (
                <Alert 
                    message={alertMessage.message} 
                    type={alertMessage.type} 
                    onClose={() => setAlertMessage({ message: '', type: '' })} 
                />
            )}
            {!task ? (
                <div className='dialog-tasks-add'>
                    <h1>Création d'une tâche</h1>
                    <div className='container'>
                        <div className='input-box'>
                            <label htmlFor="client">Entreprise/Client</label>
                            <input type="text" name="client" id="client" value={formData.client} onChange={handleChange} />
                        </div>
                        <div className='input-box'>
                            <label htmlFor="folder">Numéro du dossier</label>
                            <input type="text" name="folder" id="folder" value={formData.folder} onChange={handleChange} inputMode="numeric" onKeyDown={handleKeyDown} />
                        </div>
                        <div className='input-box'>
                            <label htmlFor="description">Description de la tâche</label>
                            <textarea name="description" id="description" value={formData.description} onChange={handleChange}></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="visibility">Attribuer à</label>
                            <select name="visibility" id="visibility" value={formData.visibility} onChange={handleChange}>
                                <option value="individual">Moi</option>
                                <option value="group">Au groupe</option>
                            </select>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="priority">La priorité de la tâche</label>
                            <select name="priority" id="priority" value={formData.priority} onChange={handleChange}>
                                <option value="level_1">Niveau 1</option>
                                <option value="level_2">Niveau 2</option>
                                <option value="level_3">Niveau 3</option>
                                <option value="level_4">Niveau 4</option>
                                <option value="level_5">Niveau 5</option>
                            </select>
                        </div>
                        <div className='container-btn'>
                            <button onClick={handleClose}>Annuler</button>
                            <button onClick={handleSubmit}>Créer la tâche</button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='dialog-tasks-view'>
                    <h1>Détails de la tâche</h1>
                    <div className='container'>
                        <div className='input-box'>
                            <label htmlFor="client">Entreprise/Client</label>
                            <input type="text" name="client" id="client" value={formData.client} readOnly />
                        </div>
                        <div className='input-box'>
                            <label htmlFor="folder">Numéro du dossier</label>
                            <input type="text" name="folder" id="folder" value={formData.folder} readOnly />
                        </div>
                        <div className='input-box'>
                            <label htmlFor="description">Description de la tâche</label>
                            <textarea name="description" id="description" value={formData.description} readOnly></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="visibility">Attribuer à</label>
                            <input type="text" name="visibility" id="visibility" value={formData.visibility === 'group' ? 'Au groupe' : 'Moi'} readOnly />
                        </div>
                        <div className='input-box'>
                            <label htmlFor="priority">La priorité de la tâche</label>
                            <input type="text" name="priority" id="priority" value={`Niveau ${task.priority}`} readOnly />
                        </div>
                        <div className='container-btn'>
                            <button onClick={handleClose}>Fermer</button>
                            <button 
                                onClick={isTaskStarted ? handleStopTask : handleStartTask} 
                                style={{ backgroundColor: isTaskStarted ? '#cf5e5e' : '#6fbe7a' }}
                            >
                                {isTaskStarted ? 'Arrêter' : 'Commencer'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TasksDialog;
