import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from '../Alert/Alert';
import "./UserManagementDialog.css";

function UserManagementDialog() {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = location.state || {};

    const [closing, setClosing] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
    const [formData, setFormData] = useState({
        username: user ? user.username : '',
        password: '',
        permission: user ? user.permission : 'moderator',
    });

    useEffect(() => {
        const dialog = document.querySelector('.background-dialog-users');
        setTimeout(() => {
            dialog.classList.add('show');
        }, 10);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {
        const url = user ? 'https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/UserManagement.php' : 'https://todo.logixmedia.ch/todo/src/TodoLogix/Backend/UserManagement.php';
        const method = user ? 'PUT' : 'POST';

        console.log('Submitting data:', formData);

        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(response => response.json())
        .then(data => {
            if (data.message === "Utilisateur créé avec succès" || data.message === "Utilisateur mis à jour avec succès") {
                setAlertMessage({ message: data.message, type: 'success' });
                navigate('/usermanagement', { state: { updated: true, user: data.user } });
            } else {
                setAlertMessage({ message: data.message, type: 'error' });
            }
        })
        .catch(error => {
            setAlertMessage({ message: 'Échec de la mise à jour de l\'utilisateur', type: 'error' });
            console.error('Erreur:', error);
        });
    };

    const handleCloseDialog = () => {
        setClosing(true);
        setTimeout(() => {
            navigate('/usermanagement');
        }, 300);
    };

    return (
        <div className={`background-dialog-users ${closing ? 'closing' : ''}`}>
            {alertMessage.message && (
                <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({ message: '', type: '' })} />
            )}
            <div className='dialog-user-management-view'>
                <h1>{user ? 'Modifier l\'utilisateur' : 'Créer un utilisateur'}</h1>
                <div className='container'>
                    <div className='input-box'>
                        <label htmlFor="username">Nom d'utilisateur</label>
                        <input type="text" name="username" id="username" value={formData.username} onChange={handleChange} />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="password">Mot de passe</label>
                        <input type="password" name="password" id="password" value={formData.password} onChange={handleChange} />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="permission">Permission</label>
                        <select name="permission" id="permission" value={formData.permission} onChange={handleChange}>
                            <option value="moderator">Modérateur</option>
                            <option value="admin">Administrateur</option>
                        </select>
                    </div>
                    <div className='container-btn'>
                        <button onClick={handleCloseDialog}>Annuler</button>
                        <button onClick={handleSubmit}>{user ? 'Modifier' : 'Créer'}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserManagementDialog;
